<script setup>
const { initAuthentification } = useAuthentification();
initAuthentification();
</script>

<template>
    <div>
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>

<style>
body {
    font-family: "Huawei Sans";
}

h1 {
    font-family: "Huawei Bold";
}
</style>
