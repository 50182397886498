export default function useAuthentification() {
    return {
        clearAuthentification,
        initAuthentification,
        setAuthentification,
        getAuthentification
    };
}

function clearAuthentification() {
    clearNuxtState('authentication'); // der State 'authentificaton wird gelöscht, muss bei neuer Verwendung initialisiert werden!
    //clearNuxtState(); // lösche alle states
}

function initAuthentification(value: Boolean = false) {
    const stateAuthentification = useState('authentication', () => value);
}

function setAuthentification(value: Boolean = false) {
    const stateAuthentification = useState('authentication');
    if (stateAuthentification === undefined) {
        initAuthentification(value);
    } else {
        stateAuthentification.value = value;
    }
}

function getAuthentification() {
    const stateAuthentification = useState('authentication');

    if (stateAuthentification.value === undefined) {
        return 'undefined';
    }

    const isAuthenticated = stateAuthentification.value ? true : false;
    return isAuthenticated;
}