export default defineNuxtRouteMiddleware(async (to, from) => {

    const localePath = useLocalePath();
    const { loginRequired } = useRuntimeConfig().public;

    // Redirect if login required and user not authentificated
    if (loginRequired && to.path.includes(localePath('registration'))) {
        const { getAuthentification } = useAuthentification();
        const isAuthenticated = getAuthentification();

        if (!isAuthenticated) {
            return navigateTo(localePath('/'));
        }
    }
});

