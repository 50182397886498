import { default as galleryGCEJSAJk7KMeta } from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/gallery.vue?macro=true";
import { default as imprintdCHyTbCrpRMeta } from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/imprint.vue?macro=true";
import { default as indexpaO680VVyZMeta } from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/index.vue?macro=true";
import { default as privacyS4xUIO9dHOMeta } from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/privacy.vue?macro=true";
import { default as registrationwsVFv7bVZcMeta } from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/registration.vue?macro=true";
import { default as thankyouSQ0EdGejYbMeta } from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/thankyou.vue?macro=true";
import { default as component_45stubRAu5RKcBItMeta } from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_a4uikarwiptxpmuxn4m6n4dtr4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubRAu5RKcBIt } from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_a4uikarwiptxpmuxn4m6n4dtr4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "gallery___es",
    path: "/es/gallery",
    meta: galleryGCEJSAJk7KMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/gallery.vue")
  },
  {
    name: "gallery___fr",
    path: "/fr/gallery",
    meta: galleryGCEJSAJk7KMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/gallery.vue")
  },
  {
    name: "gallery___en",
    path: "/en/gallery",
    meta: galleryGCEJSAJk7KMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/gallery.vue")
  },
  {
    name: "gallery___de",
    path: "/gallery",
    meta: galleryGCEJSAJk7KMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/gallery.vue")
  },
  {
    name: "imprint___es",
    path: "/es/imprima",
    meta: imprintdCHyTbCrpRMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/imprint.vue")
  },
  {
    name: "imprint___fr",
    path: "/fr/mentions-legales",
    meta: imprintdCHyTbCrpRMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/imprint.vue")
  },
  {
    name: "imprint___en",
    path: "/en/imprint",
    meta: imprintdCHyTbCrpRMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/imprint.vue")
  },
  {
    name: "imprint___de",
    path: "/impressum",
    meta: imprintdCHyTbCrpRMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/imprint.vue")
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexpaO680VVyZMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexpaO680VVyZMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexpaO680VVyZMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/",
    meta: indexpaO680VVyZMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/index.vue")
  },
  {
    name: "privacy___es",
    path: "/es/proteccion-de-datos",
    meta: privacyS4xUIO9dHOMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/privacy.vue")
  },
  {
    name: "privacy___fr",
    path: "/fr/protection-des-donnees",
    meta: privacyS4xUIO9dHOMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/privacy.vue")
  },
  {
    name: "privacy___en",
    path: "/en/privacy-policy",
    meta: privacyS4xUIO9dHOMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/privacy.vue")
  },
  {
    name: "privacy___de",
    path: "/datenschutzerklaerung",
    meta: privacyS4xUIO9dHOMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/privacy.vue")
  },
  {
    name: "registration___es",
    path: "/es/inscripcion",
    meta: registrationwsVFv7bVZcMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/registration.vue")
  },
  {
    name: "registration___fr",
    path: "/fr/enregistrement",
    meta: registrationwsVFv7bVZcMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/registration.vue")
  },
  {
    name: "registration___en",
    path: "/en/registration",
    meta: registrationwsVFv7bVZcMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/registration.vue")
  },
  {
    name: "registration___de",
    path: "/registrierung",
    meta: registrationwsVFv7bVZcMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/registration.vue")
  },
  {
    name: "thankyou___es",
    path: "/es/gracias",
    meta: thankyouSQ0EdGejYbMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/thankyou.vue")
  },
  {
    name: "thankyou___fr",
    path: "/fr/merci",
    meta: thankyouSQ0EdGejYbMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/thankyou.vue")
  },
  {
    name: "thankyou___en",
    path: "/en/thankyou",
    meta: thankyouSQ0EdGejYbMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/thankyou.vue")
  },
  {
    name: "thankyou___de",
    path: "/danke",
    meta: thankyouSQ0EdGejYbMeta || {},
    component: () => import("/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/pages/thankyou.vue")
  },
  {
    name: component_45stubRAu5RKcBItMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubRAu5RKcBIt
  },
  {
    name: component_45stubRAu5RKcBItMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubRAu5RKcBIt
  },
  {
    name: component_45stubRAu5RKcBItMeta?.name,
    path: "/es-ES-sitemap.xml",
    component: component_45stubRAu5RKcBIt
  },
  {
    name: component_45stubRAu5RKcBItMeta?.name,
    path: "/fr-FR-sitemap.xml",
    component: component_45stubRAu5RKcBIt
  },
  {
    name: component_45stubRAu5RKcBItMeta?.name,
    path: "/en-UK-sitemap.xml",
    component: component_45stubRAu5RKcBIt
  },
  {
    name: component_45stubRAu5RKcBItMeta?.name,
    path: "/de-DE-sitemap.xml",
    component: component_45stubRAu5RKcBIt
  }
]