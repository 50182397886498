
// @ts-nocheck


export const localeCodes =  [
  "es",
  "fr",
  "en",
  "de"
]

export const localeLoaders = {
  "es": [{ key: "../locales/es.json", load: () => import("../locales/es.json" /* webpackChunkName: "locale__Users_andreas_www_projekte_nuxt_i18n_tw_pv_content_dev_locales_es_json" */), cache: true },
{ key: "../locales/es-custom.json", load: () => import("../locales/es-custom.json" /* webpackChunkName: "locale__Users_andreas_www_projekte_nuxt_i18n_tw_pv_content_dev_locales_es_custom_json" */), cache: true }],
  "fr": [{ key: "../locales/fr.json", load: () => import("../locales/fr.json" /* webpackChunkName: "locale__Users_andreas_www_projekte_nuxt_i18n_tw_pv_content_dev_locales_fr_json" */), cache: true },
{ key: "../locales/fr-custom.json", load: () => import("../locales/fr-custom.json" /* webpackChunkName: "locale__Users_andreas_www_projekte_nuxt_i18n_tw_pv_content_dev_locales_fr_custom_json" */), cache: true }],
  "en": [{ key: "../locales/en.json", load: () => import("../locales/en.json" /* webpackChunkName: "locale__Users_andreas_www_projekte_nuxt_i18n_tw_pv_content_dev_locales_en_json" */), cache: true },
{ key: "../locales/en-custom.json", load: () => import("../locales/en-custom.json" /* webpackChunkName: "locale__Users_andreas_www_projekte_nuxt_i18n_tw_pv_content_dev_locales_en_custom_json" */), cache: true }],
  "de": [{ key: "../locales/de.json", load: () => import("../locales/de.json" /* webpackChunkName: "locale__Users_andreas_www_projekte_nuxt_i18n_tw_pv_content_dev_locales_de_json" */), cache: true },
{ key: "../locales/de-custom.json", load: () => import("../locales/de-custom.json" /* webpackChunkName: "locale__Users_andreas_www_projekte_nuxt_i18n_tw_pv_content_dev_locales_de_custom_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "name": "Español 🇪🇸",
      "code": "es",
      "flag": "🇪🇸",
      "files": [
        "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/locales/es.json",
        "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/locales/es-custom.json"
      ],
      "language": "es-ES",
      "languageName": "Español"
    },
    {
      "name": "Français 🇫🇷",
      "code": "fr",
      "flag": "🇫🇷",
      "files": [
        "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/locales/fr.json",
        "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/locales/fr-custom.json"
      ],
      "language": "fr-FR",
      "languageName": "Français"
    },
    {
      "name": "English 🇬🇧",
      "code": "en",
      "flag": "🇬🇧",
      "files": [
        "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/locales/en.json",
        "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/locales/en-custom.json"
      ],
      "language": "en-UK",
      "languageName": "English"
    },
    {
      "name": "Deutsch 🇩🇪",
      "code": "de",
      "flag": "🇩🇪",
      "files": [
        "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/locales/de.json",
        "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/locales/de-custom.json"
      ],
      "language": "de-DE",
      "languageName": "Deutsch"
    }
  ],
  "defaultLocale": "de",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "http://localhost:3000",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "about": {
      "de": "/ueber-uns",
      "en": "/about-us",
      "fr": "/a-propos-de-nous",
      "es": "/quienes-somos"
    },
    "privacy": {
      "de": "/datenschutzerklaerung",
      "en": "/privacy-policy",
      "fr": "/protection-des-donnees",
      "es": "/proteccion-de-datos"
    },
    "imprint": {
      "de": "/impressum",
      "en": "/imprint",
      "fr": "/mentions-legales",
      "es": "/imprima"
    },
    "registration": {
      "de": "/registrierung",
      "en": "/registration",
      "fr": "/enregistrement",
      "es": "/inscripcion"
    },
    "thankyou": {
      "de": "/danke",
      "en": "/thankyou",
      "fr": "/merci",
      "es": "/gracias"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "name": "Español 🇪🇸",
    "code": "es",
    "flag": "🇪🇸",
    "files": [
      {
        "path": "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/locales/es.json"
      },
      {
        "path": "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/locales/es-custom.json"
      }
    ],
    "language": "es-ES",
    "languageName": "Español"
  },
  {
    "name": "Français 🇫🇷",
    "code": "fr",
    "flag": "🇫🇷",
    "files": [
      {
        "path": "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/locales/fr.json"
      },
      {
        "path": "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/locales/fr-custom.json"
      }
    ],
    "language": "fr-FR",
    "languageName": "Français"
  },
  {
    "name": "English 🇬🇧",
    "code": "en",
    "flag": "🇬🇧",
    "files": [
      {
        "path": "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/locales/en.json"
      },
      {
        "path": "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/locales/en-custom.json"
      }
    ],
    "language": "en-UK",
    "languageName": "English"
  },
  {
    "name": "Deutsch 🇩🇪",
    "code": "de",
    "flag": "🇩🇪",
    "files": [
      {
        "path": "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/locales/de.json"
      },
      {
        "path": "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/locales/de-custom.json"
      }
    ],
    "language": "de-DE",
    "languageName": "Deutsch"
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
