import revive_payload_client_zauojtVGKb from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_a4uikarwiptxpmuxn4m6n4dtr4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_60B9AfG7bL from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_a4uikarwiptxpmuxn4m6n4dtr4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_dah9QLatwG from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_a4uikarwiptxpmuxn4m6n4dtr4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_5toVLZLKzs from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.3_vite@5.4.10_@types+node@22.8.4_sass@1.80_6v5yosyhq7xfbtuiaqinw5lytu/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_Y3zpVcFkGW from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_a4uikarwiptxpmuxn4m6n4dtr4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Q42PtYaHrc from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_a4uikarwiptxpmuxn4m6n4dtr4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_GnhHnPUMsM from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_a4uikarwiptxpmuxn4m6n4dtr4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ASCNryepZ6 from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_a4uikarwiptxpmuxn4m6n4dtr4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/.nuxt/components.plugin.mjs";
import prefetch_client_IvaZgBwSlZ from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_a4uikarwiptxpmuxn4m6n4dtr4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/.nuxt/primevue-plugin.mjs";
import plugin_client_oz031ZzYtg from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/node_modules/.pnpm/@primevue+nuxt-module@4.1.1_@babel+parser@7.26.1_magicast@0.3.5_rollup@4.24.3_vue@3.5.12/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import titles_XBE6ZdNfEP from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_magicast@0.3.5_rollup@4.24.3_vite@5.4.10_@types+node@22.8.4_uvuiojxxwfxluqxtkn2eanav6q/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_AxDf6vX9fa from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_magicast@0.3.5_rollup@4.24.3_vite@5.4.10_@types+node@22.8.4_uvuiojxxwfxluqxtkn2eanav6q/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_Ufn3wgnyST from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.3_vite@5.4.10_@types+node@22.8.4_sass@1_5lgzpsyehriy65st3dr7d72o7e/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_FW76LRMpzY from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.3_vite@5.4.10_@types+node@22.8.4_sass@1_5lgzpsyehriy65st3dr7d72o7e/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import switch_locale_path_ssr_SOVauB8k9J from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.3_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_IRBTgmZPD8 from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.3_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import i18n_client_gXssPVzz4q from "/Users/andreas/www-projekte/nuxt-i18n-tw-pv-content/dev/plugins/i18n.client.ts";
export default [
  revive_payload_client_zauojtVGKb,
  unhead_60B9AfG7bL,
  router_dah9QLatwG,
  _0_siteConfig_5toVLZLKzs,
  payload_client_Y3zpVcFkGW,
  navigation_repaint_client_Q42PtYaHrc,
  check_outdated_build_client_GnhHnPUMsM,
  chunk_reload_client_ASCNryepZ6,
  components_plugin_KR1HBZs4kY,
  prefetch_client_IvaZgBwSlZ,
  primevue_plugin_egKpok8Auk,
  plugin_client_oz031ZzYtg,
  titles_XBE6ZdNfEP,
  defaultsWaitI18n_AxDf6vX9fa,
  siteConfig_Ufn3wgnyST,
  inferSeoMetaPlugin_FW76LRMpzY,
  switch_locale_path_ssr_SOVauB8k9J,
  i18n_IRBTgmZPD8,
  i18n_client_gXssPVzz4q
]