import { definePreset } from '@primevue/themes';
import Nora from '@primevue/themes/nora';

const presetHuawei = definePreset(Nora, {
    semantic: {
        primary: {
            50: '{red.50}',
            100: '{red.100}',
            200: '{red.200}',
            300: '{red.300}',
            400: '{red.400}',
            500: '{red.500}',
            600: '{red.600}',
            700: '{red.700}',
            800: '{red.800}',
            900: '{red.900}',
            950: '{red.950}'
        }
    }
});

export default {
    preset: presetHuawei,
    options: {
        darkModeSelector: '.p-dark-fake'
    }
};